/* ==========
   1.BASIC
   ========== */

body {
  font-family: "Rubik", sans-serif;
  overflow-x: hidden !important;
  position: relative;
}

a,
a:hover,
a:focus,
button {
  text-decoration: none !important;
  outline: none !important;
}

p {
  font-size: 14.4px;
  line-height: 24px;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

b {
  font-weight: 500;
}

:-moz-selection {
  background-color: #2e3138;
  color: #fff;
}

::selection {
  background-color: #2e3138;
  color: #fff;
}

/*====================
  2.HELPER
  ===================*/

.margin-t-20 {
  margin-top: 20px;
}

.margin-t-30 {
  margin-top: 30px;
}

.margin-t-50 {
  margin-top: 50px;
}

.margin-b-0 {
  margin-bottom: 0px;
}

.padding-t-15 {
  padding-top: 15px;
}

.padding-t-30 {
  padding-top: 30px;
}

.padding-b-10 {
  padding-bottom: 10px;
}

.height-100vh {
  height: 100vh;
}

.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.bg-overlay {
  background: #005c97; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  opacity: 0.95;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
}

.bg-overlay-light {
  background-color: rgba(0, 0, 0, 0.5);
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.section-sm {
  padding: 50px 0;
}

.section-lg {
  padding: 100px 0;
}

.section-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0px;
  padding-bottom: 1rem;
  line-height: 1;
  font-size: 1.75rem;
}

.section-title-border {
  width: 50px;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
}

.section-subtitle {
  letter-spacing: 0.02em;
}

.svg-pattern {
  width: 100%;
  position: relative;
}

iframe {
  max-width: 100%;
}

.hover-effect {
  transition: all 0.3s ease-in-out;
}
.hover-effect:hover {
  transform: translateY(-1rem);
}

/*----BACKGROUND COLOR-----*/

.bg-gray {
  background-color: #f7f7f7;
}

.bg-white {
  background-color: #fff;
}

.bg-dark {
  background-color: #393f4f !important;
}

.bg-light {
  background-color: #fafbfe !important;
}

/*=====================
  3. BOOTSTRAP RESET
  ======================*/

/*--Text color--- */

.text-muted {
  color: #858d96 !important;
}

.text-white {
  color: #fff !important;
}

.text-gray {
  color: #777 !important;
}

.text-dark {
  color: #212121 !important;
}

/*====Buttons=======*/

.btn {
  padding: 10px 22px;
  transition: all 0.5s;
  box-shadow: none !important;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn-rounded {
  border-radius: 30px;
}

.btn-custom {
  border: 1px solid;
  color: #fff;
  transition: all 0.3s;
  font-size: 14px;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  border: 1px solid !important;
  color: #f3f3f3 !important;
}

.btn-bg-white {
  background-color: #fff !important;
  transition: all 0.3s;
  border: 2px solid #fff;
  color: #000 !important;
  font-size: 14px;
}

.read-btn {
  font-size: 15px;
}

.read-btn:hover {
  color: #212529 !important;
}

/* MY */

/*------------------------------------------------------------------[Buttons] */

.mybtn {
  border-width: 0;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  font-family: "Montserrat", Arial, serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 0.625rem;
  padding: 1.25rem 2.8125rem;
}

.mybtn > span {
  position: relative;
  z-index: 1;
}

.mybtn:before {
  content: "";
  position: absolute;
  display: block;
  z-index: 0;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  right: 0;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mybtn:hover:before {
  height: 450%;
  width: 120%;
  transition: all 0.4s linear;
}

.mybtn:focus,
.mybtn.focus,
.mybtn.active.focus,
.mybtn.active:focus,
.mybtn:active.focus,
.mybtn:active:focus {
  box-shadow: none;
  outline: 0;
}

button {
  cursor: pointer;
}

/*------------------------------------------------------------------
[Button Sizes]
*/

.mybtn-lg {
  padding: 1.5rem 3.4375rem;
}

.mybtn-sm {
  padding: 1rem 2.1875rem;
}

/*------------------------------------------------------------------
[Button Colors]
*/

.mybtn.mybtn-brand {
  background: #202020;
  color: #fff;
}

.mybtn.mybtn-brand:before {
  background: #e41919;
}

.mybtn.mybtn-brand.mybtn-outline {
  background: transparent;
  border: 1px solid #202020;
  color: #525252;
}

.mybtn.mybtn-brand.mybtn-outline:before {
  background: #202020;
}

.mybtn.mybtn-brand.mybtn-outline:hover {
  border-color: #202020;
  color: #fff;
}

.mybtn.mybtn-gray {
  background: #4c4c4c;
  color: #fff;
}

.mybtn.mybtn-gray:before {
  background: #e41919;
}

.mybtn.mybtn-gray.mybtn-outline {
  border: 1px solid #4c4c4c;
  color: #525252;
  background: transparent;
}

.mybtn.mybtn-gray.mybtn-outline:before {
  background: #4c4c4c;
}

.mybtn.mybtn-gray.mybtn-outline:hover {
  border-color: #4c4c4c;
  color: #fff;
}

.play-mybtn {
  display: inline-block;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  padding: 0 0 0 3px;
  transition: 0.2s linear;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #202020;
}

.play-mybtn:after {
  font-family: "Ionicons";
  content: "\f488";
  line-height: 1;
}

.play-mybtn:hover {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  color: #202020;
}

/*======================
  4.NAVBAR
  ======================*/
.logo {
  font-size: 20px;
}

.navbar-bcolor {
  background-color: #202020;
}

.navbar-custom {
  padding: 20px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sticky-wrapper {
  position: absolute;
  width: 100%;
}

.navbar-nav {
  margin-left: 50px;
  border-bottom: none;
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  color: #fff !important;
  font-weight: 700;
  letter-spacing: 2px;
  height: auto;
}

.navbar-custom .navbar-nav li a {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  background-color: transparent !important;
  padding: 15px 0;
  margin: 0 10px;
  transition: all 0.4s;
}

.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
  color: #fff;
}

.navbar-custom .navbar-nav li.active a {
  color: #fff;
}

.is-sticky .navbar-custom {
  margin-top: 0px;
  padding: 10px 0px;
  background-color: #272a33;
  -moz-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.navbar-toggle {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}

.navbar-custom .btn-custom {
  margin-top: 4px;
  margin-bottom: 4px;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #337ab7;
}

/*---Navbar-White--*/

.navbar-custom.navbar-white {
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.navbar-custom.navbar-white .navbar-nav li a,
.navbar-custom.navbar-white .navbar-brand,
.navbar-white .navbar-toggle {
  color: #393f4f !important;
}

/*=======================================
          5.HOME 
  =====================================*/

.bg-home {
  background: url("../images/bg-home.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-title {
  font-size: 46px;
  line-height: 64px;
  font-weight: 300;
}

.home-small-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 7px;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
}

.home-up {
  z-index: 10;
  position: relative;
}

.play-btn {
  border: 2px solid;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  font-size: 30px;
  margin-right: auto;
  margin-left: auto;
  line-height: 60px;
  margin-top: 10px;
  text-align: center;
}

.play-btn2 {
  border: 2px solid;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  font-size: 30px;
  margin-right: auto;
  margin-left: auto;
  line-height: 60px;
  margin-top: 10px;
  text-align: center;
}

.play-shadow {
  border-radius: 50%;
  border: 1px solid transparent;
  width: 82px;
  height: 82px;
  margin: 20px auto 0px auto;
}

.home-desc {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  max-width: 600px;
}

/* ------------- Demo 1 bg-pattern ------------- */

.home-half {
  padding-bottom: 150px;
  padding-top: 220px;
}

/* ------- Demo 9 wave animation --------- */

.wave-effect {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 150px;
  overflow: hidden;
}

.shape-one {
  z-index: 15;
  opacity: 0.5;
}

.shape-two {
  z-index: 10;
  opacity: 0.75;
}

.shape-three {
  z-index: 5;
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waves-shape {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wave-one {
  background-size: 50% 100px;
}

.wave-anim .wave-one {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-two {
  background-size: 50% 120px;
}

.wave-anim .wave-two {
  animation: move_wave 10s linear infinite;
}

.wave-three {
  background-size: 50% 100px;
}

.wave-anim .wave-three {
  animation: move_wave 15s linear infinite;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }

  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }

  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

/*-------Demo 4 Rgistartion form ------*/

.home-registration-form .form-heading {
  margin-bottom: 25px;
}

.home-registration-form {
  padding: 15px 32px 32px 32px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 0px #000000;
}

.home-subtitle-width-100 {
  max-width: 100%;
}

.small-text {
  font-size: 14px;
}

.home-btn-width {
  width: 100%;
}

/*----Demo 5 Dashboard image ----*/

.home-cell-bottom {
  display: table-cell;
  vertical-align: bottom;
}

.home-padding-t-150 {
  padding-top: 150px;
}

/*---Demo 6 Subscribe -----*/

.subscribe-form input {
  padding: 15px 20px;
  width: 100%;
  font-size: 17px;
  color: #4c5667 !important;
  border: none;
  outline: none !important;
  padding-right: 180px;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 30px;
}

.subscribe-form button {
  position: absolute;
  text-transform: uppercase;
  top: 6px;
  right: 8px;
  outline: none !important;
  border-radius: 30px;
  font-size: 16px;
  padding: 9px 30px;
}

.subscribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

/*----Demo 8 Home with video --------*/

.frame-border {
  border: 9px solid rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

/*---Demo 9 particles ---*/

.slidero {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

/*======================================
     6. SERVICES
========================================*/

.services-box {
  padding: 15px 10px;
}

.services-box i {
  font-size: 32px;
  margin-bottom: 15px;
}

/*=====================================
     7. ABOUT US
======================================*/

.features-box h3 {
  margin-bottom: 25px;
  margin-top: 0;
  line-height: 35px;
  font-size: 26px;
}

.features-item-list li:before {
  content: "";
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 14px;
}

.features-item-list li {
  position: relative;
  line-height: 24px;
  font-size: 15px;
  padding: 7px 0 7px 20px;
}

.features-img {
  max-width: 500px;
}

/*=====================================
     8. WEBSITE DESCRIPTION
======================================*/

.bg-web-desc {
  background: url("../images/img-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*======================================
         10. TEAM
========================================*/

.about-title h2 {
  line-height: 42px;
}

.about-title p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 300;
}

.about-title {
  max-width: 800px;
}

.team-box {
  border-bottom: 1px solid #ecf0f5;
}

.team-name {
  font-size: 16px;
  padding-top: 20px;
  margin-bottom: 7px;
}

.team-designation {
  font-size: 13px;
  color: #a8aeb4;
}

/*=======================================
     11. OUR PROCESS
=========================================*/

.process-box i {
  font-size: 48px;
  line-height: 72px;
}

.plan-line:before {
  content: "";
  border: 1px dashed #e0e0e0;
  position: absolute;
  width: 67%;
  left: 65%;
  top: 50px;
}

.process-left-icon {
  content: "";
  position: absolute;
  top: 10px;
  background-color: #000;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.process-left-icon-1 i,
.process-left-icon-2 i {
  font-size: 35px;
  border-radius: 50%;
  position: absolute;
  top: 83px;
  color: #fff;
  z-index: 9;
}

.process-left-icon-1 i {
  left: 63%;
}

.process-left-icon-2 i {
  left: 30%;
}

/*=======================================
       12. PEOPLE SAY
=========================================*/

.testimonial-decs {
  position: relative;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1) !important;
}
.testimonial-box img {
  height: 68px;
  width: 68px;
}

.testimonial-decs .testi-icon i {
  position: absolute;
  top: 0;
  right: 0;
  color: #f4f4f4;
}

.testimonial-decs p {
  line-height: 24px;
  font-style: italic;
}

/*=====================================
     13. GET STARTED
======================================*/

.bg-get-start {
  background: url("../images/img-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*=======================================
       14. BLOG
=========================================*/

.blog-title {
  color: #212529;
  transition: all 0.5s;
}

/*======================================
    15. CONTACT
=======================================*/

.form-control {
  margin-bottom: 20px;
  box-shadow: none !important;
  height: 50px;
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}

.form-control:focus {
  border-color: #ccc;
}

textarea.form-control {
  height: auto;
  padding-top: 13.5px;
}

.error {
  margin: 8px 0px;
  display: none;
  color: red;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

.error_message {
  padding: 10px;
  background-color: #fdf0d3;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

#success_page {
  text-align: center;
  margin-bottom: 20px;
  background-color: #d6f3ed;
  padding: 18px;
}

#success_page h4 {
  color: #0dba26;
  font-size: 22px;
}

/*======================================
       16. FOOTER
========================================*/

.social-icon {
  border: 2px solid #40434e;
  border-radius: 50%;
  color: #6b707d !important;
  width: 36px;
  height: 36px;
  display: block;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
}

.cta {
  padding: 20px 0;
}

.social-icon:hover i {
  transition: all 0.5s;
}

.footer {
  padding: 50px 0;
  background-color: #272a33;
}

.footer h4 {
  color: #ffffff;
}

.footer-list li a {
  color: #98a0aa;
  padding-top: 8px;
  display: inline-block;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.footer-list li a:hover {
  color: #dce6f3;
}

.contact-title {
  font-size: 20px;
}

.contact-detail {
  padding-left: 25px;
  font-size: 12px;
}

.subscribe {
  position: relative;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
}

.subscribe input {
  height: 40px;
  background-color: #2e313a !important;
  box-shadow: none !important;
  border: none;
  padding-right: 50px;
  color: #fff !important;
}

.subscribe .submit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  width: 50px;
  line-height: 45px;
  text-align: center;
  color: #acacac !important;
}

.footer-alt-border {
  background-color: #f5f5f5;
  height: 1px;
  width: 100%;
}

.footer-alt {
  padding: 15px 0;
  background-color: #2e313a;
}

.copy-rights {
  margin-bottom: 0;
  line-height: 36px;
}

/*=====================================
    17. EXTRA PAGES
====================================*/

.bg-account-pages {
  background: linear-gradient(to right, #363795, #005c97);

  opacity: 0.9;
}

.account-home-btn {
  position: absolute;
  top: 18px;
  right: 28px;
  z-index: 1;
}

.account-pages-logo {
  letter-spacing: 2px;
}

.account-card form .form-control {
  height: 45px;
}

.account-card form label {
  font-size: 15px;
}

.account-card .custom-control-input:checked ~ .custom-control-label::before {
  background: #fb3e3e;
  border-color: #fb3e3e;
}

.account-card .alert {
  font-size: 14px;
}

/*=====================================
    MYROW & MYCOL
====================================*/
.myrow {
  display: block;
  margin-right: -15px;
  margin-left: -15px;
}

.mycol-1,
.mycol-2,
.mycol-3,
.mycol-4,
.mycol-5,
.mycol-6,
.mycol-7,
.mycol-8,
.mycol-9,
.mycol-10,
.mycol-11,
.mycol-12,
.mycol,
.mycol-auto,
.mycol-sm-1,
.mycol-sm-2,
.mycol-sm-3,
.mycol-sm-4,
.mycol-sm-5,
.mycol-sm-6,
.mycol-sm-7,
.mycol-sm-8,
.mycol-sm-9,
.mycol-sm-10,
.mycol-sm-11,
.mycol-sm-12,
.mycol-sm,
.mycol-sm-auto,
.mycol-md-1,
.mycol-md-2,
.mycol-md-3,
.mycol-md-4,
.mycol-md-5,
.mycol-md-6,
.mycol-md-7,
.mycol-md-8,
.mycol-md-9,
.mycol-md-10,
.mycol-md-11,
.mycol-md-12,
.mycol-md,
.mycol-md-auto,
.mycol-lg-1,
.mycol-lg-2,
.mycol-lg-3,
.mycol-lg-4,
.mycol-lg-5,
.mycol-lg-6,
.mycol-lg-7,
.mycol-lg-8,
.mycol-lg-9,
.mycol-lg-10,
.mycol-lg-11,
.mycol-lg-12,
.mycol-lg,
.mycol-lg-auto,
.mycol-xl-1,
.mycol-xl-2,
.mycol-xl-3,
.mycol-xl-4,
.mycol-xl-5,
.mycol-xl-6,
.mycol-xl-7,
.mycol-xl-8,
.mycol-xl-9,
.mycol-xl-10,
.mycol-xl-11,
.mycol-xl-12,
.mycol-xl,
.mycol-xl-auto {
  position: relative;
  width: 70%;
  padding-right: 15px;
  padding-left: 15px;
}

/*=====================================
    18. RESPONSIVE
====================================*/

@media (min-width: 1200px) {
  .mycol-lg-1,
  .mycol-lg-10,
  .mycol-lg-11,
  .mycol-lg-12,
  .mycol-lg-2,
  .mycol-lg-3,
  .mycol-lg-4,
  .mycol-lg-5,
  .mycol-lg-6,
  .mycol-lg-7,
  .mycol-lg-8,
  .mycol-lg-9 {
    margin: 0 auto;
  }
  .timeline-item .rss-content p {
    font-size: 20px;
  }

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1400px;
  }
}

@media (min-width: 992px) {
  .mycol-lg-61 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .timeline-item .rss-content p {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .navbar-custom .navbar-nav {
    margin-left: 0;
  }
  .home-half .slidero {
    top: 80%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .home-half {
    padding-bottom: 100px;
    padding-top: 155px;
  }
  .home-padding-t-150 {
    padding-top: 110px;
  }
  .navbar-custom {
    margin-top: 0px;
    padding: 10px 0px !important;
    background-color: #272a33 !important;
    box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
    color: #fff !important;
  }

  .navbar-white {
    margin-top: 0px;
    padding: 10px 0px !important;
    background-color: #fff !important;
    box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
    color: #fff !important;
  }

  .navbar-white .navbar-toggler {
    color: #393f4f !important;
  }
  .navbar-custom .navbar-nav li a {
    transition: all 0.4s;
    margin: 0px;
  }
  .navbar-custom > .container-fluid {
    width: 100%;
  }
  .navbar-custom .navbar-nav li.active a {
    color: #fff !important;
    border-color: transparent;
  }
  .navbar-custom > .container {
    width: 90%;
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff !important;
  }
  .login-btn {
    padding-left: 10px;
  }
  .navbar-custom .navbar-brand {
    line-height: 24px;
  }

  .home-title {
    font-size: 32px;
    line-height: 48px;
  }
  .vertical-content {
    display: inherit;
  }
  .team-box {
    margin-top: 45px;
  }

  .features-img {
    margin-top: 30px;
  }
  .pricing-box {
    margin-top: 30px;
  }
}

@media (max-width: 991px) and (min-width: 200px) {
  .plan-line:before {
    content: "";
    display: none;
  }
  .process-left-icon-1 i,
  .process-left-icon-2 i {
    display: none;
  }
  .timeline-item .rss-content p {
    font-size: 17px;
  }

  .mt-m-status {
    margin-left: 30px;
    width: 70px !important;
  }

  .mt-margin-artist {
    margin-left: 25px !important;
    width: 170px;
  }

  .music-trow {
    width: 600px !important;
  }

  .music-header {
    width: 600px !important;
  }

  .mt-headers span:nth-child(2),
  .mt-headers span:nth-child(4),
  .mtcontainer td:nth-child(2),
  .mtcontainer td:nth-child(4) {
    display: none;
  }

  .timeline-item .rss-content {
    height: 230px !important;
  }
}

@media (max-width: 768px) {
  .wave-one,
  .wave-three {
    background-size: 50% 50px;
  }

  .wave-two {
    background-size: 50% 70px;
  }

  .social {
    margin-top: 0;
  }
  .social-icon {
    margin-bottom: 5px;
  }

  .contact-title {
    text-align: left;
  }

  .timeline-item .rss-content {
    height: 230px !important;
  }

  .timeline-item .rss-content p {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .pull-none {
    float: none !important;
    text-align: center;
  }

  .timeline-item .rss-content {
    height: 230px !important;
  }

  .timeline-item .rss-content p {
    font-size: 12px;
  }
}

@media (max-height: 420px) {
  .navbar-collapse {
    max-height: 200px;
    overflow-y: auto;
  }

  .timeline-item .rss-content {
    height: 230px !important;
  }

  .timeline-item .rss-content p {
    font-size: 10px;
  }
}

/*==================================
STYLE SWITHER - Demo purpose only
==================================*/

#style-switcher div h3 {
  font-size: 16px;
  margin: 8px 3px 12px;
}

#style-switcher {
  background: none repeat scroll 0 0 #ffff;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
  left: -189px;
  position: fixed;
  top: 17%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;
}

#style-switcher div {
  padding: 5px 10px;
}

#style-switcher .bottom {
  background: none repeat scroll 0 0 #ffffff;
  color: #252525;
  padding: 0;
}

#style-switcher .bottom a.settings {
  background: none repeat scroll 0 0 #2b2424;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
  display: block;
  height: 41px;
  position: absolute;
  right: -40px;
  top: 0;
  width: 40px;
  padding: 3px;
}

#style-switcher .bottom a {
  text-decoration: none;
}

#style-switcher .bottom a.settings i {
  margin-left: 5px;
  color: #ffffff;
  font-size: 24px;
  position: absolute;
}

ul.pattern {
  list-style: none outside none;
  margin: 0 0 0px;
  overflow: hidden;
  padding: 0;
  border-radius: 0px;
}

ul.pattern li {
  float: left;
  margin: 2px;
}

ul.pattern li a {
  cursor: pointer;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 30% 70% 64% 36% / 30% 42% 58% 70%;
  margin: 5px;
}

ul.pattern .color1 {
  background-color: #1bbc9d;
}

ul.pattern .color2 {
  background-color: #ee6352;
}

ul.pattern .color3 {
  background-color: #59cd90;
}

ul.pattern .color4 {
  background-color: #f85f89;
}

ul.pattern .color5 {
  background-color: #3fa7d6;
}

ul.pattern .color6 {
  background-color: #704fff;
}

ul.pattern .color7 {
  background-color: #f28123;
}

ul.pattern .color8 {
  background-color: #f6cb42;
}

@media only screen and (max-width: 479px) {
  #style-switcher {
    display: none;
  }
}

/* Styles-two */

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}

.ibox:after,
.ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
  width: 70%;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

.ibox-content {
  clear: both;
}

.ibox-heading {
  background-color: #f3f6fb;
  border-bottom: none;
}

.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
}

.ibox-title .label {
  float: left;
  margin-left: 4px;
}

.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: relative;
  padding: 0;
  text-align: right;
}

.ibox-tools a {
  /*cursor: pointer;*/
  margin-left: 5px;
  color: #c4c4c4;
}

.ibox-tools a.btn-primary {
  color: #fff;
}

.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
}

.ibox .ibox-tools.open > .dropdown-menu {
  left: auto;
  right: 0;
}

.float-e-margins .btn {
  margin-bottom: 5px;
}

.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
  display: none;
}

.display-menu {
  display: grid;
}

.hide-menu {
  display: none;
}

.dropdown-menu {
  min-width: 200px;
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  font-size: 12px;
  left: auto;
  right: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
}

.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1ab394;
  outline: 0;
}

.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
  margin-top: 5px;
}

/* Intimeline */
.timeline-item .forimage i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  border-left: 1px solid #e7eaec;
  background: #f8f8f8;
}

.timeline-item .forimage {
  text-align: right;
  width: 30px;
  position: relative;
  padding-top: 30px;
}

.timeline-item .rss-content {
  border: 1px solid #e7eaec;
  padding: 10px;
  height: 140px;
  margin: 0 auto !important;
  width: 100%;
}
/*    .timeline-item .rss-content p { font-size: calc(1vw + 1vh + 1vmin); } */

.timeline-item .rss-content:hover {
  background: #f6f6f6;
}

.timeline-item .rss-content .rss-content-width {
  width: 100%;
}

.rss-center {
  margin: 0 auto !important;
}

.zindex {
  z-index: 1034 !important;
}

.navbar-custom .navbar-nav li.active a:hover {
  color: deepskyblue;
}
