.text-custom,.read-btn,
.navbar-custom.navbar-white .navbar-nav li.active a,
.navbar-custom.navbar-white .navbar-nav li a:hover,
.navbar-custom.navbar-white .navbar-nav li a:focus,
.navbar-custom.navbar-white .navbar-nav li a:active,
.play-btn,.blog-title:hover,.social-icon:hover i{
    color:coral;
}


.section-title-border, .bg-custom, .btn-custom, .features-item-list li:before, .process-left-icon-1 i,
.process-left-icon-2 i {
    background-color: coral;
}



.btn-custom, .play-btn {
    border-color: coral;
}

.play-btn2 {
    border-color: burlywood;
    color: burlywood;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
    background-color: coral;
}


.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
    border-color: coral;
}

.material-icons.icon-align {
    font-size: 36px;
    color: burlywood;
    border-color: burlywood;
    position: relative;
    top: -15px;
}