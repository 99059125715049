.switch.switch-default {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;
}

    .switch.switch-default .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .switch.switch-default .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 2px;
        transition: opacity background 0.15s ease-out;
    }

    .switch.switch-default .switch-input:checked ~ .switch-label::before {
        opacity: 0;
    }

    .switch.switch-default .switch-input:checked ~ .switch-label::after {
        opacity: 1;
    }

    .switch.switch-default .switch-handle {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 1px;
        transition: left 0.15s ease-out;
    }

    .switch.switch-default .switch-input:checked ~ .switch-handle {
        left: 18px;
    }

    .switch.switch-default.switch-lg {
        width: 48px;
        height: 28px;
    }

        .switch.switch-default.switch-lg .switch-label {
            font-size: 12px;
        }

        .switch.switch-default.switch-lg .switch-handle {
            width: 24px;
            height: 24px;
        }

        .switch.switch-default.switch-lg .switch-input:checked ~ .switch-handle {
            left: 22px;
        }

    .switch.switch-default.switch-sm {
        width: 32px;
        height: 20px;
    }

        .switch.switch-default.switch-sm .switch-label {
            font-size: 8px;
        }

        .switch.switch-default.switch-sm .switch-handle {
            width: 16px;
            height: 16px;
        }

        .switch.switch-default.switch-sm .switch-input:checked ~ .switch-handle {
            left: 14px;
        }

    .switch.switch-default.switch-xs {
        width: 24px;
        height: 16px;
    }

        .switch.switch-default.switch-xs .switch-label {
            font-size: 7px;
        }

        .switch.switch-default.switch-xs .switch-handle {
            width: 12px;
            height: 12px;
        }

        .switch.switch-default.switch-xs .switch-input:checked ~ .switch-handle {
            left: 10px;
        }

.switch.switch-text {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 48px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;
}

    .switch.switch-text .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .switch.switch-text .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 2px;
        transition: opacity background 0.15s ease-out;
    }

        .switch.switch-text .switch-label::before, .switch.switch-text .switch-label::after {
            position: absolute;
            top: 50%;
            width: 50%;
            margin-top: -0.5em;
            line-height: 1;
            text-align: center;
            transition: inherit;
        }

        .switch.switch-text .switch-label::before {
            right: 1px;
            color: #e1e6ef;
            content: attr(data-off);
        }

        .switch.switch-text .switch-label::after {
            left: 1px;
            color: #fff;
            content: attr(data-on);
            opacity: 0;
        }

    .switch.switch-text .switch-input:checked ~ .switch-label::before {
        opacity: 0;
    }

    .switch.switch-text .switch-input:checked ~ .switch-label::after {
        opacity: 1;
    }

    .switch.switch-text .switch-handle {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 1px;
        transition: left 0.15s ease-out;
    }

    .switch.switch-text .switch-input:checked ~ .switch-handle {
        left: 26px;
    }

    .switch.switch-text.switch-lg {
        width: 56px;
        height: 28px;
    }

        .switch.switch-text.switch-lg .switch-label {
            font-size: 12px;
        }

        .switch.switch-text.switch-lg .switch-handle {
            width: 24px;
            height: 24px;
        }

        .switch.switch-text.switch-lg .switch-input:checked ~ .switch-handle {
            left: 30px;
        }

    .switch.switch-text.switch-sm {
        width: 40px;
        height: 20px;
    }

        .switch.switch-text.switch-sm .switch-label {
            font-size: 8px;
        }

        .switch.switch-text.switch-sm .switch-handle {
            width: 16px;
            height: 16px;
        }

        .switch.switch-text.switch-sm .switch-input:checked ~ .switch-handle {
            left: 22px;
        }

    .switch.switch-text.switch-xs {
        width: 32px;
        height: 16px;
    }

        .switch.switch-text.switch-xs .switch-label {
            font-size: 7px;
        }

        .switch.switch-text.switch-xs .switch-handle {
            width: 12px;
            height: 12px;
        }

        .switch.switch-text.switch-xs .switch-input:checked ~ .switch-handle {
            left: 18px;
        }

.switch.switch-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 48px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;
}

    .switch.switch-icon .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .switch.switch-icon .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-family: FontAwesome;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 2px;
        transition: opacity background 0.15s ease-out;
    }

        .switch.switch-icon .switch-label::before, .switch.switch-icon .switch-label::after {
            position: absolute;
            top: 50%;
            width: 50%;
            margin-top: -0.5em;
            line-height: 1;
            text-align: center;
            transition: inherit;
        }

        .switch.switch-icon .switch-label::before {
            right: 1px;
            color: #e1e6ef;
            content: attr(data-off);
        }

        .switch.switch-icon .switch-label::after {
            left: 1px;
            color: #fff;
            content: attr(data-on);
            opacity: 0;
        }

    .switch.switch-icon .switch-input:checked ~ .switch-label::before {
        opacity: 0;
    }

    .switch.switch-icon .switch-input:checked ~ .switch-label::after {
        opacity: 1;
    }

    .switch.switch-icon .switch-handle {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 1px;
        transition: left 0.15s ease-out;
    }

    .switch.switch-icon .switch-input:checked ~ .switch-handle {
        left: 26px;
    }

    .switch.switch-icon.switch-lg {
        width: 56px;
        height: 28px;
    }

        .switch.switch-icon.switch-lg .switch-label {
            font-size: 12px;
        }

        .switch.switch-icon.switch-lg .switch-handle {
            width: 24px;
            height: 24px;
        }

        .switch.switch-icon.switch-lg .switch-input:checked ~ .switch-handle {
            left: 30px;
        }

    .switch.switch-icon.switch-sm {
        width: 40px;
        height: 20px;
    }

        .switch.switch-icon.switch-sm .switch-label {
            font-size: 8px;
        }

        .switch.switch-icon.switch-sm .switch-handle {
            width: 16px;
            height: 16px;
        }

        .switch.switch-icon.switch-sm .switch-input:checked ~ .switch-handle {
            left: 22px;
        }

    .switch.switch-icon.switch-xs {
        width: 32px;
        height: 16px;
    }

        .switch.switch-icon.switch-xs .switch-label {
            font-size: 7px;
        }

        .switch.switch-icon.switch-xs .switch-handle {
            width: 12px;
            height: 12px;
        }

        .switch.switch-icon.switch-xs .switch-input:checked ~ .switch-handle {
            left: 18px;
        }

.switch.switch-3d {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;
}

    .switch.switch-3d .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .switch.switch-3d .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #f9f9fa;
        border: 1px solid #f9f9fa;
        border-radius: 2px;
        transition: opacity background 0.15s ease-out;
    }

    .switch.switch-3d .switch-input:checked ~ .switch-label::before {
        opacity: 0;
    }

    .switch.switch-3d .switch-input:checked ~ .switch-label::after {
        opacity: 1;
    }

    .switch.switch-3d .switch-handle {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: #fff;
        border: 1px solid #f9f9fa;
        border-radius: 1px;
        transition: left 0.15s ease-out;
        border: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    }

    .switch.switch-3d .switch-input:checked ~ .switch-handle {
        left: 16px;
    }

    .switch.switch-3d.switch-lg {
        width: 48px;
        height: 28px;
    }

        .switch.switch-3d.switch-lg .switch-label {
            font-size: 12px;
        }

        .switch.switch-3d.switch-lg .switch-handle {
            width: 28px;
            height: 28px;
        }

        .switch.switch-3d.switch-lg .switch-input:checked ~ .switch-handle {
            left: 20px;
        }

    .switch.switch-3d.switch-sm {
        width: 32px;
        height: 20px;
    }

        .switch.switch-3d.switch-sm .switch-label {
            font-size: 8px;
        }

        .switch.switch-3d.switch-sm .switch-handle {
            width: 20px;
            height: 20px;
        }

        .switch.switch-3d.switch-sm .switch-input:checked ~ .switch-handle {
            left: 12px;
        }

    .switch.switch-3d.switch-xs {
        width: 24px;
        height: 16px;
    }

        .switch.switch-3d.switch-xs .switch-label {
            font-size: 7px;
        }

        .switch.switch-3d.switch-xs .switch-handle {
            width: 16px;
            height: 16px;
        }

        .switch.switch-3d.switch-xs .switch-input:checked ~ .switch-handle {
            left: 8px;
        }

    .switch-pill .switch-label, .switch.switch-3d .switch-label, .switch-pill .switch-handle, .switch.switch-3d .switch-handle {
        border-radius: 50em !important;
    }

        .switch-pill .switch-label::before, .switch.switch-3d .switch-label::before {
            right: 2px !important;
        }

        .switch-pill .switch-label::after, .switch.switch-3d .switch-label::after {
            left: 2px !important;
        }

.switch-primary > .switch-input:checked ~ .switch-label {
    background: #20a8d8 !important;
    border-color: #1985ac;
}

.switch-primary > .switch-input:checked ~ .switch-handle {
    border-color: #1985ac;
}

.switch-primary-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #20a8d8;
}

    .switch-primary-outline > .switch-input:checked ~ .switch-label::after {
        color: #20a8d8;
    }

.switch-primary-outline > .switch-input:checked ~ .switch-handle {
    border-color: #20a8d8;
}

.switch-primary-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #20a8d8;
}

    .switch-primary-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #20a8d8;
    }

.switch-primary-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #20a8d8 !important;
    border-color: #20a8d8;
}

.switch-secondary > .switch-input:checked ~ .switch-label {
    background: #c0cadd !important;
    border-color: #9faecb;
}

.switch-secondary > .switch-input:checked ~ .switch-handle {
    border-color: #9faecb;
}

.switch-secondary-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #c0cadd;
}

    .switch-secondary-outline > .switch-input:checked ~ .switch-label::after {
        color: #c0cadd;
    }

.switch-secondary-outline > .switch-input:checked ~ .switch-handle {
    border-color: #c0cadd;
}

.switch-secondary-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #c0cadd;
}

    .switch-secondary-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #c0cadd;
    }

.switch-secondary-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #c0cadd !important;
    border-color: #c0cadd;
}

.switch-success > .switch-input:checked ~ .switch-label {
    background: #79c447 !important;
    border-color: #61a434;
}

.switch-success > .switch-input:checked ~ .switch-handle {
    border-color: #61a434;
}

.switch-success-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #79c447;
}

    .switch-success-outline > .switch-input:checked ~ .switch-label::after {
        color: #79c447;
    }

.switch-success-outline > .switch-input:checked ~ .switch-handle {
    border-color: #79c447;
}

.switch-success-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #79c447;
}

    .switch-success-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #79c447;
    }

.switch-success-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #79c447 !important;
    border-color: #79c447;
}

.switch-info > .switch-input:checked ~ .switch-label {
    background: #67c2ef !important;
    border-color: #39afea;
}

.switch-info > .switch-input:checked ~ .switch-handle {
    border-color: #39afea;
}

.switch-info-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #67c2ef;
}

    .switch-info-outline > .switch-input:checked ~ .switch-label::after {
        color: #67c2ef;
    }

.switch-info-outline > .switch-input:checked ~ .switch-handle {
    border-color: #67c2ef;
}

.switch-info-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #67c2ef;
}

    .switch-info-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #67c2ef;
    }

.switch-info-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #67c2ef !important;
    border-color: #67c2ef;
}

.switch-warning > .switch-input:checked ~ .switch-label {
    background: #fabb3d !important;
    border-color: #f9aa0b;
}

.switch-warning > .switch-input:checked ~ .switch-handle {
    border-color: #f9aa0b;
}

.switch-warning-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #fabb3d;
}

    .switch-warning-outline > .switch-input:checked ~ .switch-label::after {
        color: #fabb3d;
    }

.switch-warning-outline > .switch-input:checked ~ .switch-handle {
    border-color: #fabb3d;
}

.switch-warning-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #fabb3d;
}

    .switch-warning-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #fabb3d;
    }

.switch-warning-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #fabb3d !important;
    border-color: #fabb3d;
}

.switch-danger > .switch-input:checked ~ .switch-label {
    background: #ff5454 !important;
    border-color: #ff2121;
}

.switch-danger > .switch-input:checked ~ .switch-handle {
    border-color: #ff2121;
}

.switch-danger-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #ff5454;
}

    .switch-danger-outline > .switch-input:checked ~ .switch-label::after {
        color: #ff5454;
    }

.switch-danger-outline > .switch-input:checked ~ .switch-handle {
    border-color: #ff5454;
}

.switch-danger-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #ff5454;
}

    .switch-danger-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #ff5454;
    }

.switch-danger-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #ff5454 !important;
    border-color: #ff5454;
}

.switch-light > .switch-input:checked ~ .switch-label {
    background: #f9f9fa !important;
    border-color: #dddde3;
}

.switch-light > .switch-input:checked ~ .switch-handle {
    border-color: #dddde3;
}

.switch-light-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #f9f9fa;
}

    .switch-light-outline > .switch-input:checked ~ .switch-label::after {
        color: #f9f9fa;
    }

.switch-light-outline > .switch-input:checked ~ .switch-handle {
    border-color: #f9f9fa;
}

.switch-light-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #f9f9fa;
}

    .switch-light-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #f9f9fa;
    }

.switch-light-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #f9f9fa !important;
    border-color: #f9f9fa;
}

.switch-dark > .switch-input:checked ~ .switch-label {
    background: #384042 !important;
    border-color: #212526;
}

.switch-dark > .switch-input:checked ~ .switch-handle {
    border-color: #212526;
}

.switch-dark-outline > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #384042;
}

    .switch-dark-outline > .switch-input:checked ~ .switch-label::after {
        color: #384042;
    }

.switch-dark-outline > .switch-input:checked ~ .switch-handle {
    border-color: #384042;
}

.switch-dark-outline-alt > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: #384042;
}

    .switch-dark-outline-alt > .switch-input:checked ~ .switch-label::after {
        color: #384042;
    }

.switch-dark-outline-alt > .switch-input:checked ~ .switch-handle {
    background: #384042 !important;
    border-color: #384042;
}

/* FROM INSPINIA */
.label {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
    border-radius: 0.25em;
    line-height: 1;
    white-space: nowrap;
}

.nav .label,
.ibox .label {
    font-size: 10px;
}

.badge {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    text-shadow: none;
    white-space: nowrap;
}

.label-primary,
.badge-primary {
    background-color: #1ab394;
    color: #FFFFFF;
}

.label-success,
.badge-success {
    background-color: #1c84c6;
    color: #FFFFFF;
}

.label-warning,
.badge-warning {
    background-color: #f8ac59;
    color: #FFFFFF;
}

.label-warning-light,
.badge-warning-light {
    background-color: #f8ac59;
    color: #ffffff;
}

.label-danger,
.badge-danger {
    background-color: #ed5565;
    color: #FFFFFF;
}

.label-info,
.badge-info {
    background-color: #23c6c8;
    color: #FFFFFF;
}

.label-inverse,
.badge-inverse {
    background-color: #262626;
    color: #FFFFFF;
}

.label-white,
.badge-white {
    background-color: #FFFFFF;
    color: #5E5E5E;
}

.label-white,
.badge-disable {
    background-color: #2A2E36;
    color: #8B91A0;
}
